<template>
  <div class="productLibrarySearchResult">
    <el-page-header @back="$router.go(-1)" content="产品库图片搜索结果"></el-page-header>
    <div class="banner">
      <img src="@/assets/bannerColorSearch.png" alt="">
    </div>
    <div class="breadcrumb">
      <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/ProductLibrary' }">
          <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
          <span> 当前位置： 产品库</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>产品库图片搜索</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="f-c-c preview" v-if="ProductLibrary_previewsUrl">
      <div class="f-cl-c elimageBox">
        <el-image :src="ProductLibrary_previewsUrl" :preview-src-list="[ProductLibrary_previewsUrl]"></el-image>
        <div class="imgText">搜索图片预览</div>
      </div>
      <div class="btnBox f-c-c">
        <div class="leftBtn f-c-b">
          <div class="leftText">选择分类</div>
          <el-select v-model="productType" placeholder="请选择" @change="changeProductType">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="rightBtn" @click="showImageUploadBox = true">
          重新搜索
        </div>
      </div>
    </div>

    <div class="list" v-if="searchList.length">
      <div class="listtitle">产品列表</div>
      <div class="tip">
        已搜索到与搜索图片相似的{{ searchTotla }}件产品
      </div>

      <div class="listBox f-w">
        <div class="item f-cl-c" v-for="(item, index) of searchList" :key="index" @click="toDetailPage(item.productId)">
          <img class="itemimg" :src="item.img1Url">
          <div class="product_cornermark">{{ item.productTypeStr }}</div>
          <span class="product_name">{{ item.productName }}</span>
          <div class="f-c-b infoBox">
            <span class="product_price">
              {{ item.unitPrice ? item.valuationCurrency : '' }}{{ item.unitPrice ? item.unitPrice.toFixed(2) : '暂无报价' }}
            </span>
            <span class="inventoryQuantity">库存数量：{{ item.inventoryQuantity.toFixed(2) }}</span>
          </div>

          <div class="f-c-b infoBox">
            <span v-if="item.productNumber" class="product_number">产品编号:{{ item.productNumber }}</span>
            <!-- <span :class="item.shortageFlag == 'Y' ? 'stateY' : 'stateN'">{{ item.shortageFlag == 'Y' ? '售罄' :
              '在售' }}</span> -->
            <span :style="{ color: item.inventoryQuantity != 0 && item.shortageFlag == 'N' ? '#67c23a' : '#888' }">{{
              item.shortageFlag == 'Y' ? '对外显示缺货' : (item.inventoryQuantity == 0 ? '缺货' : '在售')
            }}</span>
          </div>
          <div class="btnBox f-cl-c-c" @click.stop="showData(item)">
            <div class="picturecomp">
              图片对比<i class="el-icon-arrow-right"></i>
            </div>
            <span class="product_similarity" v-if="item.score">（相似度: {{ (Math.round(item.score * 10000)) / 100 +
              '%' }}）</span>

          </div>
        </div>
      </div>
    </div>
    <el-empty v-else description="暂无相关产品！"></el-empty>

    <!-- 弹窗 -->
    <Dialog :title="'图片对比'" width="810px" :visible="dialogShow" @closeDialog="closeDialog" class="commonDialog f-cl-c">
      <div class="similarity" v-if="popshowdata.score">相似度: {{ (Math.round(popshowdata.score * 10000)) / 100
        +
        '%' }}</div>
      <div class="imgBox f-c-c">
        <div class="f-cl-c leftimg">
          <el-image :src="ProductLibrary_previewsUrl" :preview-src-list="[ProductLibrary_previewsUrl]"></el-image>
          <span>所选图片</span>
        </div>
        <div class="f-cl-c">
          <el-image :src="popshowdata.img1Url" :preview-src-list="[popshowdata.img1Url]"></el-image>
          <span>搜索到的产品图</span>
        </div>
      </div>
      <div class="textBox" @click="toDetailPage(popshowdata.productId), dialogShow = false">
        <div class="firstline f-c-b">
          <div class="productName">{{ popshowdata.productName }}</div>
          <div class="cornermark">{{ popshowdata.productTypeStr }}</div>
        </div>
        <div class="secondline">
          <!-- <span :class="popshowdata.shortageFlag == 'Y' ? 'stateY' : 'stateN'">{{ popshowdata.shortageFlag == 'Y'
            ? '售罄' :
            '在售' }}</span> -->
          <span
            :style="{ color: popshowdata.inventoryQuantity != 0 && popshowdata.shortageFlag == 'N' ? '#67c23a' : '#888' }">{{
              popshowdata.shortageFlag == 'Y' ? '对外显示缺货' : (popshowdata.inventoryQuantity == 0 ? '缺货' : '在售')
            }}</span>
        </div>
        <div class="thirdline f-c-b">
          <span class="number">{{ popshowdata.productNumber }}</span>
          <span class="price">{{ popshowdata.unitPrice ? popshowdata.valuationCurrency : '' }}{{
            popshowdata.unitPrice
            ?
            popshowdata.unitPrice.toFixed(2) : '暂无报价'
          }}</span>
        </div>
      </div>
    </Dialog>

    <!-- 图片搜索 -->
    <ImageUpload @closeUploadBox="closeUploadBox" v-show="showImageUploadBox" :imageUploadTrends="imageUploadTrends"
      :ImageSearchtype="'ProductLibrarySimilaritysearch'" @getQueryList="getUploadImageSearchList"
      :key="new Date().getTime() + 2">
    </ImageUpload>
  </div>
</template>

<script>
import { UnitAccount } from "@/utils/attributeValue1Options"
export default {
  name: 'productLibrarySearchResult',
  components: {
    ImageUpload: () => import(/* webpackChunkName: "ImageUpload" */ '@/components/ImageUpload.vue')
  },
  data() {
    return {
      searchList: [],
      searchTotla: 0,
      productType: 0,
      options: [],  // 产品类型列表
      ProductLibrary_previewsUrl: '',  // 预览的图片
      ProductLibrary_queryList: [], // 搜索图片后的产品id
      dialogShow: false,
      popshowdata: {},   // 相似弹窗数据
      showImageUploadBox: false, // 搜索图片组件
      imageUploadTrends: {
        'position': 'absolute',
        'right': '500px',
        'top': '500px'
      },

    }
  },
  created() {
    let that = this;
    that.ProductLibrary_previewsUrl = that.$getStorage('ProductLibrary_previewsUrl');
    that.options = that.$getStorage('ProductTypeList');
    that.productType = that.$getStorage('ProductLibrary_productType') ?? 0;
    that.ProductLibrary_queryList = that.$getStorage('ProductLibrary_queryList')
    that.aLiEnterpriseProductImageSearchList()
  },
  methods: {
    getDate() {
      this.aLiEnterpriseProductImageSearchList();
    },
    aLiEnterpriseProductImageSearchList() {
      let that = this,
        productIdList = that.ProductLibrary_queryList;
      // params = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
      if (!productIdList.length) {
        that.searchList = [];
        return false;
      }
      that.$http.aLiEnterpriseProductImageSearchList({
        lastModifyUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        productIdList
      }).then(res => {
        if (res.code == 200) {
          let { records } = res.data;
          records.forEach(item => {
            item.productTypeStr = UnitAccount.getproductType(item.productType)
          })
          let EnterpriseproductIdSimilarityList = that.$store.state.keepEnterpriseProductIdSimilarityList,
            searchList = res.data.records;
          console.log('EnterpriseproductIdSimilarityList', EnterpriseproductIdSimilarityList)
          let newList = EnterpriseproductIdSimilarityList.map(item => {
            let hasSimilarityList = searchList.find(i => i.productId == item.productId)
            if (hasSimilarityList) {
              return {
                ...hasSimilarityList,
                ...item
              }
            }
          })
          that.searchList = this.$common.filterUndefined(newList);
          console.log('searchList', that.searchList)
          that.searchTotla = res.data.total
        }
      })
    },
    changeProductType(value) {
      this.$setStorage('ProductLibrary_productType', value);
      // this.$store.commit('productType', value);
      this.Similaritysearch();
    },
    Similaritysearch() {
      let that = this,
        ProductLibrary_previewsformdataParma = this.$getStorage('ProductLibrary_previewsformdataParma')
      // enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
      const formdata = new FormData();
      formdata.append("file", that.$store.state.ProductLibrary_saveFileData);
      formdata.append("num", ProductLibrary_previewsformdataParma.num ?? 20);
      formdata.append("productType", that.$getStorage('ProductLibrary_productType') ?? 0);
      formdata.append("lastModifyUserId", parseInt(that.$store.state.userId));
      formdata.append("token", that.$getStorage('token'));
      that.aLiEnterpriseProductImageSearch(formdata);
    },
    aLiEnterpriseProductImageSearch(formdata) {
      let that = this;
      that.$http.aLiEnterpriseProductImageSearch(formdata).then(res => {
        if (res.code == 200) {
          let productIdList = [],
            newArr = [];//创建空数组
          for (let i = 0; i < res.data.length; i++) {  //遍历图一数组
            const obj = res.data[i];  //创建一个新对象，将图一数组的每一个值赋给obj对象
            newArr.push({//给新数组里添加内容
              'productId': obj.productId,
              'score': obj.score
            })
          }
          this.$store.commit('keepEnterpriseProductIdSimilarityList', newArr);
          res.data.forEach(item => {
            productIdList.push(item.productId)
          })
          that.ProductLibrary_queryList = productIdList;
          that.$setStorage('ProductLibrary_queryList', productIdList);
          that.getDate();
        }
      })
    },
    // 跳转到产品详情
    toDetailPage(productId) {
      this.$router.push({
        name: 'ProductLibraryDetail',
        query: {
          key: this.$UrlEncode.encode(JSON.stringify({
            id: productId
          }))
        }
      })
    },
    showData(data) {
      this.dialogShow = true;
      this.popshowdata = data;
    },
    // 关闭弹框
    closeDialog(data) {
      this.dialogShow = data;
    },
    // 关闭上传图片盒子
    closeUploadBox(value) {
      this.showImageUploadBox = value
    },
    // 获取
    getUploadImageSearchList(list) {
      this.ProductLibrary_queryList = list;
      this.$setStorage('ProductLibrary_queryList', list);
      this.ProductLibrary_previewsUrl = this.$getStorage('ProductLibrary_previewsUrl');
      this.getDate();
    },
  },
}

</script>
<style lang='less' scoped>
.productLibrarySearchResult {
  .banner {
    height: 100px;
    width: 1280px;
    margin: 40px 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .breadcrumb {
    padding-bottom: 30px;
    border-bottom: 1px solid #F8F8F8;

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
      color: #666666 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
      color: #0363FA !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .preview {
    .elimageBox {
      margin-right: 60px;

      .el-image {
        width: 448px;
        height: 220px;
        border-radius: 20px;
        margin-bottom: 20px;
      }

      .imgText {
        font-size: 18px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .btnBox {
      .leftBtn {
        width: 180px;
        height: 30px;
        border-radius: 10px;
        border: 1px solid #EDEDEE;
        margin-right: 10px;
        padding: 10px;

        .leftText {
          font-size: 17px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #333333;
        }

        /deep/.el-input__inner {
          width: 100px;
          padding: 0;
          border: none;
          font-size: 17px;
          font-family: HarmonyOS Sans SC;
          // text-align: right;
          font-weight: bold;
          color: #333333;
          // border: 1px solid red;
          padding-left: 5px;
        }
      }

      .rightBtn {
        width: 145px;
        height: 50px;
        border-radius: 10px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        color: #2D73FA;
        background-color: #EFF4FA;

      }
    }
  }

  .tip {
    font-size: 16px;
    font-family: HarmonyOS Sans SC;
    font-weight: 500;
    color: #999999;
    margin-bottom: 30px;
  }

  .list {
    .listtitle {
      font-size: 24px;
      font-family: HarmonyOS Sans SC;
      font-weight: 500;
      color: #000000;
      line-height: 50px;
    }

    .tip {
      font-size: 16px;
      font-family: HarmonyOS Sans SC;
      font-weight: 500;
      color: #999999;
      margin-bottom: 30px;
    }

    .listBox {

      .item {
        background: #F6F8FB;
        border-radius: 10px;
        width: 248px;
        height: 355px;
        margin-bottom: 20px;
        margin-right: 8px;
        position: relative;
        cursor: pointer;
        // padding-bottom: 10px;

        // margin: 12px;
        .itemimg {
          margin-top: 12px;
          width: 224px;
          height: 168px;
          border-radius: 10px;

        }

        .product_cornermark {
          position: absolute;
          right: 20px;
          top: 20px;
          border-radius: 5px;
          background: #ffffff;
          text-align: center;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          color: #699EF2;
          padding: 7px 10px;
        }


        .product_name {
          width: 100%;
          font-size: 18px;
          line-height: 25px;
          font-family: HarmonyOS Sans SC;
          font-weight: 500;
          color: #333333;
          text-align: left;
          margin-left: 25px;
          margin-top: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        // .product_price {
        //   width: 100%;
        //   font-size: 14px;
        //   font-family: Poppins;
        //   font-weight: 500;
        //   color: #FF4E36;
        //   text-align: left;
        //   margin-left: 25px;
        //   margin-top: 10px;
        // }

        .infoBox {
          margin-top: 10px;
          width: calc(100% - 25px);

          .product_price {
            width: 40%;
            font-size: 14px;
            font-family: Poppins;
            font-weight: 500;
            color: #FF4E36;
            // text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // margin-left: 25px;
            // margin-top: 10px;
          }

          .inventoryQuantity {
            font-size: 14px;
            color: #999
          }

          .product_number {
            width: 50%;
            height: 15px;
            font-size: 12px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #666666;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .stateY {
            font-size: 16px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #FF4E36;
          }

          .stateN {
            font-size: 16px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #06BC95;
          }
        }

        .btnBox {
          margin-top: 10px;
          width: 110px;
          height: 60px;
          background: #CFE7FA;
          // opacity: 0.2;
          border-radius: 10px;

          .picturecomp {
            width: 68px;
            height: 18px;
            border-radius: 5px;
            font-size: 12px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #FFFFFF;
            background: linear-gradient(90deg, #026EF4 0%, #38A8F8 100%);
            text-align: center;
            line-height: 18px;
            cursor: pointer;
          }

          .product_similarity {
            margin-top: 10px;
            // border: 1px solid red;
            font-size: 12px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #699EF2;
            // display: block;
          }
        }

      }
    }
  }

  .commonDialog {
    /deep/ .el-dialog {
      border-radius: 20px;

      /deep/ .el-dialog__title {
        font-size: 36px !important;
        font-family: HarmonyOS Sans SC !important;
        font-weight: 500 !important;
        color: #000000 !important;
      }
    }

    /deep/.el-dialog__header {
      padding: 30px;
    }

    /deep/ .el-dialog__title {
      margin-top: 40px !important;
      font-size: 36px !important;
      font-family: HarmonyOS Sans SC !important;
      font-weight: 500 !important;
      color: #000000 !important;
    }

    .similarity {
      width: 100% !important;
      font-size: 18px;
      font-family: HarmonyOS Sans SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
    }

    .imgBox {
      margin: 30px 0;

      .el-image {
        width: 200px;
        height: 200px;
        border-radius: 10px;
      }

      span {
        font-size: 18px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #666666;
        margin-top: 10px;
      }

      .leftimg {
        margin-right: 120px;
      }
    }

    .textBox {
      width: 240px;
      height: 74px;
      border: 1px solid #E2E2E2;
      margin: auto;
      border-radius: 20px;
      padding: 26px 30px;
      cursor: pointer;

      .firstline {
        .productName {
          width: 50%;
          font-size: 23px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #000000;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .cornermark {
          // width: 50%;
          border-radius: 5px;
          background: #E6F1FE;
          // opacity: 0.1;
          text-align: center;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          color: #699EF2;
          padding: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .secondline {
        margin: 10px 0;

        .stateY {
          font-size: 16px;
          font-family: HarmonyOS Sans SC;
          font-weight: 500;
          color: #FF4E36;
        }

        .stateN {
          font-size: 16px;
          font-family: HarmonyOS Sans SC;
          font-weight: 500;
          color: #06BC95;
        }
      }

      .thirdline {
        .number {
          width: 50%;
          height: 22px;
          font-size: 17px;
          line-height: 17px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #999999;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .price {
          width: 50%;
          font-size: 20px;
          font-family: Poppins;
          font-weight: 500;
          color: #FF4E36;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
    }

  }

  .product-item {
    padding: 15px;
    border-bottom: 1px solid #ddd;

    .item-image {
      width: 90px;
      height: 90px;
      margin-right: 50px;
    }

    .left {
      span {
        font-size: 14px;
      }

      span:first-child {
        font-size: 20px;
      }

      span:nth-child(2) {
        margin: 15px 0;
      }

      span:last-child {
        color: red;
      }
    }

    .right {
      color: #888;
      margin-right: 50px;
      font-size: 14px;

      span:nth-child(2) {
        margin: 15px 0;
      }
    }
  }

  .product-item:hover {
    background-color: rgb(233, 250, 255);
    cursor: pointer;
  }
}
</style>
